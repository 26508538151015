import { UAContext, UserAgent } from '@quentin-sommer/react-useragent';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useState } from 'react';
import Collapse from '~components/collapse';
import Typography from '~components/typography';
import { useTranslation } from '~i18n';
import { Instagram, More, Pinterest, Tiktok, Twitter, Youtube } from '~svg-components';
import styles from './location.module.scss';

type LocationType = {
  location: string;
  code: string;
  href: string;
  links: Array<any>;
  socialMedia: Array<any>;
};

const LocationLink = (value: LocationType, isOpen: boolean) => {
  const { t } = useTranslation('student_portal');
  const router = useRouter();
  return (
    <div className={classNames('location__links', { 'location__links--open': isOpen })}>
      <Typography
        component="p"
        variant="body3"
        style={{ textDecoration: 'underline' }}
        cursor="pointer"
        color="var(--color-purple_4)"
        onClick={() => {
          if (value?.href?.indexOf('residences') > -1) {
            router.push(value.href);
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          } else {
            window.open(value?.href);
          }
        }}
      >
        {t('view_rooms_book_now')}
      </Typography>
      <div className="location__hrefs">
        {value?.links?.map((item, index) => {
          return (
            <span key={index}>
              <Typography
                variant="body3"
                style={{ textDecoration: 'underline' }}
                cursor="pointer"
                color="var(--color-purple_4)"
                onClick={() => {
                  window.open(item?.href);
                }}
              >
                {t(`location.${item?.label}`)}
              </Typography>
              {index !== value?.links?.length - 1 && (
                <Typography
                  color="var(--color-purple_4)"
                  className="location__hrefs-line"
                  variant="meta"
                >
                  |
                </Typography>
              )}
            </span>
          );
        })}
        <div className="location__socaial-media">
          {value?.socialMedia?.map(item => {
            return (
              <a
                key={`social${item?.type}`}
                href={item?.href}
                target="_blank"
                rel="noopener noreferrer"
                className="location__socaial-media-link"
              >
                {item?.type === 'Instagram' && (
                  <Instagram width={18} height={18} fill="var(--color-purple_4)" />
                )}
                {item?.type === 'Tiktok' && (
                  <Tiktok width={18} height={18} fill="var(--color-purple_4)" />
                )}
                {item?.type === 'Twitter' && (
                  <Twitter width={18} height={18} fill="var(--color-purple_4)" />
                )}
                {item?.type === 'Pinterest' && (
                  <Pinterest width={18} height={18} fill="var(--color-purple_4)" />
                )}
                {item?.type === 'Youtube' && <Youtube width={23} fill="var(--color-purple_4)" />}
              </a>
            );
          })}
        </div>
      </div>
      <style jsx>{`
        .location {
          &__links {
            padding-top: 8px;
            max-height: 0;
            overflow: hidden;
            transition: 0.3s ease-out;
            &--open {
              max-height: 200px;
            }
          }
          &__hrefs {
            margin-top: 4px;
          }
          :global(&__hrefs-line) {
            margin-left: 4px;
            margin-right: 4px;
          }
          &__socaial-media {
            margin-top: 12px;
            display: flex;
            align-items: center;
          }
          &__socaial-media-link {
            margin-right: 12px;
          }
        }
        @media screen and (max-width: 768px) {
          .location {
            &__links {
              padding: 0 20px 24px;
            }
          }
        }
      `}</style>
    </div>
  );
};

interface ILocationProps {
  value: LocationType;
}
const Location: React.FC<ILocationProps & React.HTMLAttributes<HTMLDivElement>> = props => {
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { t } = useTranslation('public');

  const [isOpen, setIsOpen] = useState(isMobile);
  const { value } = props;

  return (
    <div className={classNames('location', props.className)}>
      <UserAgent mobile>
        <Collapse color="var(--color-white)" title={t(`location.${value?.code}`)}>
          {LocationLink(value, isOpen)}
        </Collapse>
      </UserAgent>
      <UserAgent computer>
        <>
          <div
            className={styles.name}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <Typography variant="body2" className={styles.locationText}>
              {t(`location.${value?.code}`)}
            </Typography>
            {value?.location && (
              <More
                width={14}
                fill="var(--color-white)"
                className={classNames(styles.more, { [styles.moreOpen]: isOpen })}
              />
            )}
          </div>
          {LocationLink(value, isOpen)}
        </>
      </UserAgent>
    </div>
  );
};

export default Location;
