import classNames from 'classnames';
import { useRouter } from 'next/router';
import React from 'react';
import BookingLoading from '~components/booking-process/booking-loading';
import Footer from '~components/footer';
import Header from '~components/header';
import styles from './layout.module.scss';

interface Props {
  noNeedHeaderFooter?: boolean;
  className?: string;
  layoutFlexible?: boolean;
  hiddenLoading?: boolean;
}
const Layout: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  noNeedHeaderFooter,
  className,
  children,
  layoutFlexible,
  hiddenLoading,
}) => {
  const router = useRouter();
  const isBookingProcess = router.pathname === '/booking-process';

  return (
    <div className={classNames(styles.root, { 'layout-flexable': layoutFlexible })}>
      {!noNeedHeaderFooter && <Header />}
      <div
        className={classNames(
          { [styles.content]: !noNeedHeaderFooter },
          { [styles.contentProcess]: isBookingProcess },
          { [styles.contentFlexiable]: layoutFlexible },
          className
        )}
      >
        {isBookingProcess && !hiddenLoading && (
          <div className={styles.loading}>
            <BookingLoading />
          </div>
        )}
        <div className={classNames(styles.layoutWrapper, { [styles.wrapper]: isBookingProcess })}>
          {children}
        </div>
      </div>
      {!noNeedHeaderFooter && <Footer />}
      <style jsx global>
        {`
          @font-face {
            font-family: 'UniversalSans';
            src: url('/static/fonts/UniversalSans-900.ttf') format('truetype');
            font-weight: bold;
            font-style: normal;
          }
          @font-face {
            font-family: 'UniversalSans';
            src: url('/static/fonts/UniversalSans-600.ttf') format('truetype');
            font-weight: normal;
            font-style: normal;
          }
          html {
            * {
              box-sizing: border-box;
              &:before,
              &:after {
                box-sizing: border-box;
              }
            }
            font-family: 'UniversalSans';
            &:lang(el),
            &:lang(vi-vn),
            &:lang(ko-kr),
            &:lang(ja-jp),
            &:lang(th-th),
            &:lang(ru-ru) {
              font-family: 'Helvetica, Arial, sans-serif';
            }

            &:lang(zh-tw),
            &:lang(zh-hk) {
              font-family: "Helvetica, Arial, 'Hiragino Sans GB', 'Microsoft JhengHei', sans-serif";
            }
            &:lang(zh-cn) {
              font-family: 'Helvetica Neue, Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Microsoft JhengHei, sans-serif';
            }
            &:lang(zh-cn),
            &:lang(zh-tw),
            &:lang(ko-kr),
            &:lang(ja-jp) {
              u,
              strike,
              em,
              i,
              a {
                text-decoration: none;
              }
            }
          }
          body {
            margin: 0;
            -webkit-tap-highlight-color: transparent !important;
          }
          input {
            -webkit-appearance: none;
            box-shadow: none !important;
          }
          @media screen and (-webkit-min-device-pixel-ratio: 0) and (max-device-width: 1024px) {
            select,
            textarea,
            input {
              font-size: 14px !important;
            }
          }
          p {
            margin: 0;
          }
          ul,
          li {
            margin: 0;
            padding: 0;
            list-style: none;
          }
          :global(.layout-flexable) {
            display: flex;
            flex-direction: column;
          }
          .ReactModal__Body--open {
            overflow-y: hidden;
          }
          .overflow-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          *:focus {
            -webkit-tap-highlight-color: transparent !important;
            outline: none !important;
          }
          .tippy-tooltip.white-theme {
            background-color: white;
            color: var(--color-purple_6);
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;

            .tippy-arrow {
              display: none;
            }
            .tippy-content {
              padding: 0;
              pointer-events: auto;
            }
          }

          .tippy-tooltip.white-arrow-theme {
            background-color: white;
            color: var(--color-purple_6);
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            .tippy-arrow {
              border-top-color: var(--color-white);
              box-shadow: 0 -1px 16px 0 rgba(51, 53, 50, 0.14);
            }
            .tippy-content {
              padding: 0;
              pointer-events: auto;
            }
          }

          .tippy-tooltip.blue-arrow-theme {
            background-color: var(--color-yellow_6);
            color: var(--color-white);
            top: 0 !important;
            left: 0 !important;
            right: 0 !important;
            .tippy-arrow {
              border-bottom-color: var(--color-yellow_6);
            }
            .tippy-content {
              padding: 0;
              pointer-events: auto;
            }
          }

          .base_tippy_pop {
            border-radius: 14px;
            background: rgba(76, 76, 76, 0.9);
          }
          .base_tippy_pop .tippy-content {
            padding: 12px;
          }
          .base_tippy_pop[data-placement^='top'] > .tippy-arrow {
            border-top-color: rgba(76, 76, 76, 0.9) !important;
          }
        `}
      </style>
    </div>
  );
};

export default Layout;
