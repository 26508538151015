import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { i18n, useTranslation } from '~i18n';
import { More } from '~svg-components';
import supportLanguagesJson from '~variables/support-languages.json';
import Typography from '../typography';

interface LanguageSelectorProps {}

const LanguageSelectorMobile = React.forwardRef<
  any,
  LanguageSelectorProps & React.ImgHTMLAttributes<HTMLImageElement>
>((props, ref) => {
  const { t } = useTranslation('public');
  const router = useRouter();
  const [currentLanguage, setCurrenLanguage] = useState('');
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false);
  const { supportLanguages } = supportLanguagesJson;
  useEffect(() => {
    setCurrenLanguage(i18n.language);
  }, [i18n.language]);

  const handleSelecLanguage = (language: string) => {
    if (
      language !== currentLanguage &&
      supportLanguages.indexOf(language) !== -1 &&
      router.asPath
    ) {
      window.location.href = router.asPath.replace(currentLanguage, language);
    }
  };

  return (
    <div className={classNames('language-selector-mobile', props.className)} ref={ref}>
      <div style={{ width: '100%' }}>
        <Typography
          component="div"
          variant="body3"
          className={classNames('language-selector-mobile-content')}
          onClick={() => {
            setIsLanguageSelectorOpen(!isLanguageSelectorOpen);
          }}
        >
          <span>{t(`language_selector.${currentLanguage}`)}</span>
          <More
            className="language-selector-mobile-more"
            width={16}
            height={12}
            color="var(--color-purple_4)"
          />
        </Typography>
        {isLanguageSelectorOpen && (
          <ul className="language-mobile-list">
            {supportLanguages &&
              supportLanguages.map(language => (
                <li
                  className="language-mobile-item"
                  key={language}
                  onClick={() => {
                    handleSelecLanguage(language);
                  }}
                >
                  {t(`language_selector.${language}`)}
                </li>
              ))}
          </ul>
        )}
      </div>
      <style jsx>{`
        .language-selector-mobile {
          width: 100%;
        }

        :gloabl(.language-selector-mobile-content) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          size: 20px;
          font-weight: 400;
          width: 100%;
          padding: 16px 20px;
          border-bottom: ${isLanguageSelectorOpen ? '1px solid var(--color-outline)' : 'none'};
        }

        .language-mobile-list {
          padding: 6px 0;
        }

        .language-mobile-item {
          padding: 10px 40px;
        }

        :global(.language-selector-mobile-more) {
          margin-left: 16px;
          transition: transform 0.3s;
          transform: ${isLanguageSelectorOpen ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
        }
      `}</style>
    </div>
  );
});

export default LanguageSelectorMobile;
