import cn from 'classnames';
import React, { useState } from 'react';
import Typography from '~components/typography';
import { More } from '~svg-components';
interface ICollapseProps {
  title: React.ReactNode;
  onOpen?: () => void;
  onClose?: () => void;
  isDefaultOpen?: boolean;
  disable?: boolean;
  color?: any;
}
const Collapse: React.FC<ICollapseProps> = ({
  title,
  onOpen,
  onClose,
  children,
  isDefaultOpen,
  disable,
  color,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen || false);
  return (
    <div className="collapse-component">
      <div
        className="collapse-component__title"
        onClick={() => {
          if (disable) {
            return;
          }
          if (isOpen) {
            if (onClose) {
              onClose();
            }
          } else {
            if (onOpen) {
              onOpen();
            }
          }
          setIsOpen(!isOpen);
        }}
      >
        <Typography variant="body3" color={color}>
          {title}
        </Typography>
        {!disable && (
          <More
            className={cn('collapse-component__icon', { 'collapse-component__icon--open': isOpen })}
            width="15"
            height="18"
            fill={color || 'var(--color-greyText)'}
          />
        )}
      </div>
      <div
        className={cn('collapse-component__content', {
          'collapse-component__content--open': isOpen,
        })}
      >
        {children}
      </div>
      <style jsx>{`
        .collapse-component {
          width: 100%;
          box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.15);
          &__title {
            width: 100%;
            height: 60px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          :global(&__icon) {
            transition: transform 0.2s ease-out;
          }
          :global(&__icon--open) {
            transform: rotate(-180deg);
          }
          &__content {
            display: none;
            &--open {
              display: block;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default Collapse;
