import { UserAgent } from '@quentin-sommer/react-useragent';
import React from 'react';
import ContentLoader from 'react-content-loader';

const BookingLoading: React.FC = props => {
  return (
    <div className="booking-loading">
      <UserAgent computer>
        <ContentLoader
          uniqueKey="booking-loading"
          viewBox="0 0 800 280"
          height={280}
          width={800}
          speed={2}
          {...props}
        >
          <rect x="0" y="0" rx="14" ry="14" width="800" height="30" />
          <rect x="0" y="50" rx="14" ry="14" width="800" height="50" />
          <rect x="0" y="120" rx="14" ry="14" width="800" height="50" />
          <rect x="0" y="200" rx="14" ry="14" width="800" height="50" />
        </ContentLoader>
      </UserAgent>
      <UserAgent mobile>
        <ContentLoader
          uniqueKey="booking-loading"
          viewBox="0 0 300 300"
          height={300}
          width={300}
          speed={2}
          {...props}
        >
          <rect x="0" y="0" rx="14" ry="14" width="300" height="30" />
          <rect x="0" y="50" rx="14" ry="14" width="300" height="50" />
          <rect x="0" y="120" rx="14" ry="14" width="300" height="50" />
          <rect x="0" y="200" rx="14" ry="14" width="300" height="50" />
        </ContentLoader>
      </UserAgent>
      <style jsx>
        {`
          .booking-loading {
            text-align: center;
            margin: 20px auto;
          }
          @media screen and (max-width: 768px) {
            .booking-loading {
              text-align: center;
              box-sizing: border-box;
            }
          }
        `}
      </style>
    </div>
  );
};

export default BookingLoading;
