import { UAContext } from '@quentin-sommer/react-useragent';
import Tippy from '@tippy.js/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useState } from 'react';
import { i18n, useTranslation } from '~i18n';
import { Language, More } from '~svg-components';
import supportLanguagesJson from '~variables/support-languages.json';
import Typography from '../typography';

interface LanguageSelectorProps {
  type: string;
}

const LanguageSelector = React.forwardRef<
  any,
  LanguageSelectorProps & React.ImgHTMLAttributes<HTMLImageElement>
>((props, ref) => {
  const { t } = useTranslation('public');
  const router = useRouter();
  const [currentLanguage, setCurrenLanguage] = useState('');
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false);
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const { supportLanguages } = supportLanguagesJson;

  useEffect(() => {
    setCurrenLanguage(i18n.language);
  }, [i18n.language]);

  const handleSelecLanguage = (language: string) => {
    if (
      language !== currentLanguage &&
      supportLanguages.indexOf(language) !== -1 &&
      router.asPath
    ) {
      window.location.href = router.asPath.replace(currentLanguage, language);
    }
  };

  const { type } = props;
  return (
    <div
      className={classNames('language-selector', props.className, {
        'language-selector--footer': type === 'footer',
      })}
      ref={ref}
    >
      <div style={{ height: '100%' }}>
        <Tippy
          duration={100}
          theme={'white'}
          animation="scale"
          flip={false}
          interactive={true}
          visible={isLanguageSelectorOpen}
          onHidden={e => {
            setIsLanguageSelectorOpen(false);
          }}
          placement={type === 'header' ? 'bottom-start' : 'top-start'}
          appendTo={e => e}
          content={
            <ul
              className={classNames('language-list', {
                'language-list--footer': type === 'footer',
              })}
            >
              {supportLanguages &&
                supportLanguages.map(language => (
                  <li
                    className={classNames('language-item', {
                      'language-item--selected': language === currentLanguage,
                      'language-item--footer': type === 'footer',
                    })}
                    key={language}
                    onClick={() => {
                      handleSelecLanguage(language);
                    }}
                  >
                    <Typography variant="body3">{t(`language_selector.${language}`)}</Typography>
                  </li>
                ))}
            </ul>
          }
        >
          <Typography
            component="div"
            variant={isMobile ? 'body3' : 'body2'}
            color={type === 'footer' ? 'var(--color-outline)' : 'var(--color-purple_6)'}
            className={classNames(
              'language-selector-content',
              `language-selector-content--${type}`
            )}
            onClick={() => {
              setIsLanguageSelectorOpen(!isLanguageSelectorOpen);
            }}
          >
            <Language
              height={16}
              width={16}
              fill={type === 'footer' ? 'var(--color-outline)' : 'var(--color-purple_6)'}
              style={{
                marginRight: 9,
              }}
            />
            <span>{currentLanguage ? t(`language_selector.${currentLanguage}`) : ''}</span>
            {type === 'footer' && (
              <More
                className={classNames('language-selector-more', {
                  'language-selector-more--footer': type === 'footer',
                })}
                height={12}
                width={12}
                fill="var(--color-outline)"
              />
            )}
          </Typography>
        </Tippy>
      </div>
      <style jsx>
        {`
          :global(.language-selector) {
            height: 100%;
            cursor: pointer;
          }

          :global(.language-selector--footer) {
            padding-top: 28px;
          }

          :global(.language-selector-content) {
            display: flex;
            padding: 0 28px;
            font-weight: normal;
            align-items: center;
          }
          :global(.language-selector-content--footer) {
            height: auto;
            padding: 0 4px;
            color: var(--color-outline);
          }

          :global(.language-selector-content--header) {
            height: 100%;
          }

          .language-list {
            border: solid 1px var(--color-outline);
            box-shadow: 0 1px 3px 0 var(--color-outline);

            & > :global(*:not(:first-child)) {
              border-top: solid 1px var(--color-outline);
            }
          }

          .language-list--footer {
            & > :global(*:not(:first-child)) {
              border-top: none;
            }
          }

          .language-item {
            padding: 16px 45px 16px 45px;
            background-color: var(--color-white);

            &--selected {
              box-shadow: inset 5px 0 0 0 var(--color-purple_6);
              background-color: var(--color-background);
            }

            &:hover {
              background-color: var(--color-background);
            }
          }

          .language-item--footer {
            min-width: 100px;
            padding: 10px 12px;
            font-weight: 400;
            box-shadow: none;
          }

          :global(.language-selector-more) {
            margin-left: 8px;
            transition: transform 0.3s;
          }

          :global(.language-selector-more--footer) {
            transform: ${isLanguageSelectorOpen ? 'rotateZ(180deg)' : 'rotateZ(0deg)'};
          }
        `}
      </style>
    </div>
  );
});

export default LanguageSelector;
