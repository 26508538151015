import { UAContext, UserAgent } from '@quentin-sommer/react-useragent';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useContext, useMemo, useRef } from 'react';
import { OrgOptions, useThemeContext } from '~components/theme-provider';
import Typography from '~components/typography';
import { useTranslation } from '~i18n';
import { BeyooLogo, ColivingLogo, UreLogo, YugoLogo } from '~svg-components';
import { toLowerCaseAndTrim } from '~utils/js-helper';
import locationsJson from '~variables/locations.json';
import LanguageSelector from '../language-selector';
import styles from './footer.module.scss';
import Location from './location';

const Footer: React.FC<any & React.HTMLAttributes<HTMLDivElement>> = props => {
  const { t } = useTranslation('student_portal');
  const footerSelectRef = useRef(null);
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;

  const { propertyWithEnabledAcademicYear, ORG } = useThemeContext();
  const { locations } = locationsJson;

  const propertyLocations = useMemo(() => {
    const propertyCountrys = propertyWithEnabledAcademicYear?.map(item => {
      return {
        id: item?.country?.id || '',
        name: item?.country?.name || '',
      };
    });
    const landlordCountries = _.uniqWith(propertyCountrys, _.isEqual);

    if (landlordCountries?.length > 0) {
      return locations?.map(ele => {
        const countryHasProperty = landlordCountries?.find(
          item => toLowerCaseAndTrim(item?.name || '') === toLowerCaseAndTrim(ele?.location)
        );
        if (countryHasProperty) {
          return {
            ...ele,
            href: `/residences?country=${countryHasProperty?.id}`,
          };
        }
        return ele;
      });
    }
    return locations;
  }, [propertyWithEnabledAcademicYear, locations]);

  return (
    <div className={classNames(styles.footer, props.className)}>
      <UserAgent computer>
        <div className={styles.container}>
          <div className={styles.logos}>
            {ORG === OrgOptions.YUGO && <YugoLogo width={100} height={100} />}
            {ORG === OrgOptions.BEYOO && <BeyooLogo width={120} height={35} />}
            {ORG === OrgOptions.URE && <UreLogo width={130} height={42} />}
            {ORG === OrgOptions.COLIVING && <ColivingLogo width={130} height={42} />}
          </div>
          <div className={styles.locations}>
            <Typography
              component="p"
              variant="h6"
              style={{ marginBottom: 12 }}
              className={styles.locationText}
            >
              {t('locations')}
            </Typography>
            <div className={styles.locationsWrapper}>
              {propertyLocations.map(item => {
                return <Location value={item} className={styles.location} key={item?.location} />;
              })}
            </div>
            <div className={styles.convenience}>
              <Typography
                variant={isMobile ? 'meta' : 'body3'}
                className={styles.convenienceText}
                component="p"
              >
                {t('for_your_convenience_and_security')}
              </Typography>
              <Typography
                variant={isMobile ? 'meta' : 'body3'}
                className={styles.convenienceText}
                component="p"
              >
                {t('effortlessly_process_your_payment_and_confirm_your_reservation')}
              </Typography>
            </div>
          </div>
        </div>
      </UserAgent>
      <UserAgent mobile>
        <div className={styles.container}>
          <div className={styles.logos}>
            {ORG === OrgOptions.BEYOO && <BeyooLogo width={120} height={35} />}
            {ORG === OrgOptions.YUGO && <YugoLogo width={64} height={64} />}
            {ORG === OrgOptions.URE && <UreLogo width={80} />}
            {ORG === OrgOptions.COLIVING && <ColivingLogo width={80} />}
          </div>

          <div className={styles.locations}>
            {propertyLocations.map(item => {
              return <Location value={item} key={`m-${item?.location}`} />;
            })}
          </div>
          <div className={styles.convenience}>
            <Typography
              variant={isMobile ? 'meta' : 'body3'}
              className={styles.convenienceText}
              component="p"
            >
              {t('for_your_convenience_and_security')}
            </Typography>
            <Typography
              variant={isMobile ? 'meta' : 'body3'}
              className={styles.convenienceText}
              component="p"
            >
              {t('effortlessly_process_your_payment_and_confirm_your_reservation')}
            </Typography>
          </div>
        </div>
      </UserAgent>
      <div className={styles.copyRight}>
        <div className={styles.copyRightWrapper}>
          <Typography variant={isMobile ? 'meta' : 'body2'} className={styles.copyRightTitle}>
            © {new Date().getFullYear()} Student Suite
          </Typography>
          <LanguageSelector
            className={styles.languageSelector}
            type="footer"
            ref={footerSelectRef}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
