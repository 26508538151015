import { UAContext } from '@quentin-sommer/react-useragent';
import Tippy from '@tippy.js/react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useContext, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Button from '~components/button';
import { OrgOptions, useThemeContext } from '~components/theme-provider';
import { Authorization } from '~constants/cookie-properties';
import { Link, Router, useTranslation } from '~i18n';
import { RootState } from '~store';
import { disappearLoginModal, displayLoginModal } from '~store/login/actions';
import { removeViewerInfo } from '~store/user/actions';
import {
  BeyooLogo,
  Close,
  ColivingLogo,
  Menu,
  ProfileHead,
  UreLogo,
  YugoLogo,
} from '~svg-components';
import { deleteCookie } from '~utils/cookie-operation';
import LanguageSelector, { LanguageSelectorMobile } from '../language-selector';
import Typography, { TypographyProps } from '../typography';
import styles from './header.module.scss';

const WelcomeTippyContentItem = React.forwardRef<any, TypographyProps & React.HTMLAttributes<any>>(
  (props, ref) => {
    return (
      <Typography
        className={styles.WelcomeTippyContentItemStyles}
        textAlign="center"
        {...props}
        ref={ref}
      >
        {props.children}
      </Typography>
    );
  }
);

const MenuModalContentItem = React.forwardRef<any, TypographyProps & React.HTMLAttributes<any>>(
  (props, ref) => {
    return (
      <Typography
        className={styles.MenuModalContentItemStyles}
        variant="body3"
        {...props}
        ref={ref}
      >
        {props.children}
      </Typography>
    );
  }
);

interface HeaderProps {}

const Header: React.FC<HeaderProps & React.HTMLAttributes<HTMLDivElement>> = props => {
  const { t } = useTranslation();
  const uaResults = useContext(UAContext).uaResults as UAResults;
  const isMobile = uaResults.mobile;
  const dispatch = useDispatch();
  const router = useRouter();
  const headerSelector = useRef(null);
  const { pathname } = router;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { viewer } = useSelector((state: RootState) => state);
  const studentInfo = viewer.studentInfo;
  const { ...otherProps } = props;
  const { ORG } = useThemeContext();

  const myBookingsElement = (
    <Link href="/my-bookings" passHref>
      {isMobile ? (
        <MenuModalContentItem>{t('my_bookings')}</MenuModalContentItem>
      ) : (
        <Typography variant="body2" className={styles.navbarItem}>
          {t('my_bookings')}
        </Typography>
      )}
    </Link>
  );

  const profileElement = (
    <Link href="/my-profile" passHref>
      {isMobile ? (
        <MenuModalContentItem>{t('profile')}</MenuModalContentItem>
      ) : (
        <WelcomeTippyContentItem variant="body2">{t('profile')}</WelcomeTippyContentItem>
      )}
    </Link>
  );

  const logoutElement = (
    <div
      onClick={() => {
        deleteCookie(Authorization);
        dispatch(removeViewerInfo());
        Router.push('/login');
      }}
    >
      {isMobile ? (
        <MenuModalContentItem>{t('log_out')}</MenuModalContentItem>
      ) : (
        <WelcomeTippyContentItem cursor="pointer" variant="body2">
          {t('log_out')}
        </WelcomeTippyContentItem>
      )}
    </div>
  );

  const languageSelectorElement = isMobile ? (
    <MenuModalContentItem component="div" style={{ paddingLeft: '0' }}>
      <LanguageSelectorMobile ref={headerSelector} />
    </MenuModalContentItem>
  ) : (
    <LanguageSelector type="header" ref={headerSelector} />
  );

  const welcomeElementTippyContent = (
    <div>
      {profileElement}
      {logoutElement}
      <style jsx>{`
        border: solid 1px var(--color-outline);
        background-color: var(--color-white);
        box-shadow: 0 1px 3px 0 var(--color-outline);
        & > :global(*:not(:first-child)) {
          border-top: solid 1px var(--color-outline);
        }
      `}</style>
    </div>
  );

  const welcomeElement = isMobile ? (
    <MenuModalContentItem component="div">
      <ProfileHead className={styles.welcomeIcon} />
      <span style={{ marginLeft: 16 }}>Hi, {`${studentInfo?.preferredName}`}</span>
    </MenuModalContentItem>
  ) : (
    <Tippy
      content={welcomeElementTippyContent}
      duration={100}
      theme={'white'}
      trigger="click"
      hideOnClick={'toggle'}
      animation="scale"
      flip={false}
      placement="bottom-end"
      appendTo={e => e}
    >
      <div className={classNames(styles.navbarItem, 'welcome')}>
        <ProfileHead className={styles.welcomeIcon} />
        <Typography variant="body2" style={{ marginLeft: 16 }}>
          {t('header_hi', {
            student: studentInfo?.preferredName,
          })}
        </Typography>
        <style jsx>
          {`
            .welcome {
              height: 100%;
              padding: 0 16px;
            }
          `}
        </style>
      </div>
    </Tippy>
  );

  const loginElement = (
    <div>
      {pathname === '/booking-process' || pathname === '/filter' || pathname === '/residences' ? (
        <div
          onClick={() => {
            dispatch(
              displayLoginModal({
                isOpen: true,
                type: 'LOGIN',
                onClose: () => {
                  dispatch(disappearLoginModal());
                },
                onConfirm: () => {
                  dispatch(disappearLoginModal());
                },
              })
            );
          }}
        >
          {isMobile ? (
            <MenuModalContentItem> {t('log_in')}</MenuModalContentItem>
          ) : (
            <Typography
              variant={isMobile ? 'body3' : 'body2'}
              component="a"
              className={styles.navbarItem}
            >
              {t('log_in')}
            </Typography>
          )}
        </div>
      ) : (
        <Link href="/login" passHref>
          {isMobile ? (
            <MenuModalContentItem> {t('log_in')}</MenuModalContentItem>
          ) : (
            <Typography
              variant={isMobile ? 'body3' : 'body2'}
              component="a"
              className={styles.navbarItem}
            >
              {t('log_in')}
            </Typography>
          )}
        </Link>
      )}
    </div>
  );

  const createAccountElement = (
    <div>
      {pathname === '/booking-process' || pathname === '/filter' || pathname === '/residences' ? (
        <div
          onClick={() => {
            dispatch(
              displayLoginModal({
                isOpen: true,
                type: 'CREATE_ACCOUNT',
                onClose: () => {
                  dispatch(disappearLoginModal());
                },
                onConfirm: () => {
                  dispatch(disappearLoginModal());
                },
              })
            );
          }}
        >
          {isMobile ? (
            <MenuModalContentItem>{t('create_account')}</MenuModalContentItem>
          ) : (
            <Button>{t('create_account')}</Button>
          )}
        </div>
      ) : (
        <Link href="/create-account" passHref={isMobile}>
          {isMobile ? (
            <MenuModalContentItem>{t('create_account')}</MenuModalContentItem>
          ) : (
            <Button>{t('create_account')}</Button>
          )}
        </Link>
      )}
    </div>
  );

  const navbarElement = (
    <div className="navbar">
      {isMobile ? (
        <div
          className="menu"
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
          }}
        >
          {isMenuOpen ? (
            <Close width={20} height={20} fill="var(--color-purple_6)" />
          ) : (
            <Menu width={24} height={24} fill="var(--color-purple_6)" />
          )}
        </div>
      ) : (
        <>
          {languageSelectorElement}
          {studentInfo && (
            <>
              {myBookingsElement}
              {welcomeElement}
            </>
          )}
          {!studentInfo && (
            <>
              {loginElement}
              {createAccountElement}
            </>
          )}
        </>
      )}
      <style jsx>
        {`
          .navbar {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            & :global(> :not(:first-child)) {
              margin-left: 20px;
            }
            & > :global(.language) {
              & :global(> svg) {
                margin-right: 8px;
              }
            }
          }
          :global(.welcome) {
            margin-left: 48px !important;
          }
          .menu {
            height: 100%;
            width: 53px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: ${isMenuOpen ? 'var(--color-white)' : 'unset'};
          }
        `}
      </style>
    </div>
  );

  const menuModalContentElement = (
    <div className="menuModalContent">
      {studentInfo ? (
        <>
          {welcomeElement}
          {myBookingsElement}
          {profileElement}
          {languageSelectorElement}
          {logoutElement}
        </>
      ) : (
        <>
          {languageSelectorElement}
          {loginElement}
          {createAccountElement}
        </>
      )}
      <style jsx>
        {`
          .menuModalContent {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            background: var(--color-white);
          }
        `}
      </style>
    </div>
  );

  return (
    <div {...otherProps} className={classNames(styles.root, props.className)}>
      <div className={styles.header}>
        <div className={styles.header__content}>
          {ORG === OrgOptions.YUGO && (
            <YugoLogo className={styles.logoYugo} width={48} height={48} />
          )}
          {ORG === OrgOptions.BEYOO && <BeyooLogo width={120} height={35} />}
          {ORG === OrgOptions.URE && <UreLogo className={styles.ureLogo} />}
          {ORG === OrgOptions.COLIVING && <ColivingLogo className={styles.ureLogo} />}

          {navbarElement}
        </div>
      </div>
      <ReactModal
        isOpen={isMenuOpen}
        onRequestClose={() => setIsMenuOpen(false)}
        overlayClassName={styles.modalOverlayStyles}
        className={styles.modalStyles}
      >
        {menuModalContentElement}
      </ReactModal>
    </div>
  );
};
export default Header;
